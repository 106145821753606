.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.SignUpModalComp-modal
  .modal-dialog
  .modal-content
  .modal-body
  .signup-right
  form
  .form-group
  textarea.signup-input {
  height: auto !important;
}

/*  popup css */
.modal-color {
  color: #e83e8c;
}
.modal-color:hover {
  color: #cb007b;
}
.btn-confirm {
  background-color: rgb(203, 0, 123);
  min-width: 120px;
  border-radius: 50rem !important;
}
.email-link {
  text-decoration: underline;
}

 .empty-restaurant-pic {
  background: #f7f7f7;
}

/* mobile screen modal (Contact Us form) */

@media screen and (max-width: 280px) {
  .signup-left {
    display: none;
  }
  .signup-right {
    margin: 20px;
  }

  .modalclose-icon {
    height: 10px;
    width: 42px;
    position: absolute;
    right: 8px;
  }

  .brandon-Bold {
    font-size: 23px;
    display: flex;
    align-items: center;
    line-height: 42px;
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .signup-left {
    display: none;
  }
  .signup-right {
    margin: 20px;
  }

  .modalclose-icon {
    height: 20px;
    width: 45px;
    position: absolute;
    right: 15px;
  }

  .brandon-Bold {
    display: flex;
    align-items: center;
    line-height: 42px;
  }
}

@media screen and (min-width: 576px) and (max-width: 1199px) {
  .partner-right-wrapper {
    margin: 0px;
    padding: 2rem 3rem !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1199px) {
  .signup-left {
    display: none;
    /* background-image: url("./assets/images/signup-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    flex: 0 0 370px;
    max-width: 370px; */
  }
  .h1 {
    padding: 20px;
  }

  .modalclose-icon {
    height: 30px;
    width: 45px;
    position: absolute;
    right: 15px;
  }

  .brandon-Bold {
    padding-right: 15px;
    display: flex;
    align-items: center;
    line-height: 42px;
  }
}

@media screen and (min-width: 999px) and (max-width: 1024px) {
  .restdet-addupdate-btn {
    position: absolute;
    right: 20px;
    bottom: 7px;
  }
}
