// @import url(https://db.onlinewebfonts.com/c/d365bd25dab9cb26c8171719170dfe81?family=Brandon+Text);
// @import url(https://db.onlinewebfonts.com/c/749ba57b431f4eba8b31eb57d5aab83e?family=Brandon+Text+Medium);
// @import url(https://db.onlinewebfonts.com/c/47b83f8dbd3bf93e0df5674b32c6a7e4?family=BrandonTextWeb-Regular);


/*-------BrandonTextWeb-Bold-------------*/
@font-face {
    font-family: "Brandon Text";
    src: url("../../assets/font-family/Brandon_Text.ttf") format("truetype");
    /* chrome firefox opera Safari, Android, iOS 4.2+*/

}

/*-------BrandonTextWeb-Medium-------------*/
@font-face {
    font-family: "Brandon Text Medium";

    src: url("../../assets/font-family/Brandon_Medium.ttf") format("truetype");
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

/*-------BrandonTextWeb-Regular-------------*/
@font-face {
    font-family: "BrandonTextWeb-Regular";
    src: url("../../assets/font-family/Brandon_Bold.ttf") format("truetype");
    /* chrome firefox opera Safari, Android, iOS 4.2+*/

}

html,
body {
    font-family: "BrandonTextWeb-Regular" !important;
    height: 100%;
    // overflow-x: hidden;
    
    @media screen and (max-width:767px){
        overflow-x: hidden;
    }
}

body {
    color: #232323;
}

.f-11 {
    font-size: 11px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.pink-txt {
    color: #CB007B !important;
}

.line-height-1_75 {
    line-height: 1.75;
}

.bg-pink {
    background: #CB007B;
}

.h-80 {
    height: 80px;
}

.brandon-regular {
    font-family: "BrandonTextWeb-Regular" !important;
}

.brandon-Medium {
    font-family: "Brandon Text Medium" !important;
}

.brandon-Bold {
    font-family: "Brandon Text" !important;
}

.fw-400 {
    font-weight: 400;
}

.fw-600 {
    font-weight: 600;
}

.text-dark {
    color: #232323 !important;
}

.txt-lightgray {
    color: #919191;
}

.txt-darkgreen {
    color: #2E482B;
}

.list-style-none {
    list-style: none;
}

hr {
    border-color: #D9D9D9 !important;
}

.section-heading {
    color: #2E482B;
    font-size: 24px;
}

.gray_shadow{
    filter: grayscale(100%);
}


.theme-pink-btn {
    width: 50px;
    height: 50px;
    border-radius: 25px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #CB007B, #FF75BA);
    border: none !important;
    transition: background 0.5s !important;

    &:hover {
        background: linear-gradient(90deg, #CB007B, #FF75BA);
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem #d00b812e !important;
    }

    &.w-160 {
        width: 160px;
    }
}

.lightgray-btn {
    border: 1px solid #C1C1C1 !important;
    background: #fff !important;
    color: #C1C1C1 !important;
    border-radius: 20px !important;

    &:hover {
        background: #C1C1C1 !important;
        color: #fff !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem #ececec !important;
    }
}


// @media screen and (min-width:992px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width: 980px !important;
//     }
// }
// @media screen and (min-width:1200px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width: 1140px !important;
//     }
// }
// @media screen and (min-width:1200px) and (max-width:2400px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width: 100% !important;
//     }
// }

// @media screen and (min-width:1200px) and (max-width:1400px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width    : 100% !important;
//         padding-left : 5% !important;
//         padding-right: 5% !important;
//     }
// }

// @media screen and (min-width:1401px) and (max-width:1600px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         padding-left : 10% !important;
//         padding-right: 10% !important;
//     }
// }

// @media screen and (min-width:1601px) and (max-width:1800px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         padding-left : 15% !important;
//         padding-right: 15% !important;
//     }
// }

// @media screen and (min-width:1800px) and (max-width:2400px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         padding-left : 20% !important;
//         padding-right: 20% !important;
//     }
// }

