// @import url(https://db.onlinewebfonts.com/c/d365bd25dab9cb26c8171719170dfe81?family=Brandon+Text);
// @import url(https://db.onlinewebfonts.com/c/749ba57b431f4eba8b31eb57d5aab83e?family=Brandon+Text+Medium);
// @import url(https://db.onlinewebfonts.com/c/47b83f8dbd3bf93e0df5674b32c6a7e4?family=BrandonTextWeb-Regular);

/*-------BrandonTextWeb-Bold-------------*/
@font-face {
  font-family: "Brandon Text";
  src: url("../../assets/font-family/Brandon_Text.ttf") format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

/*-------BrandonTextWeb-Medium-------------*/
@font-face {
  font-family: "Brandon Text Medium";

  src: url("../../assets/font-family/Brandon_Medium.ttf") format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

/*-------BrandonTextWeb-Regular-------------*/
@font-face {
  font-family: "BrandonTextWeb-Regular";
  src: url("../../assets/font-family/Brandon_Bold.ttf") format("truetype"); /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

html,
body {
  font-family: "BrandonTextWeb-Regular" !important;
  height: 100%;
}

.MuiFormLabel-root,
.MuiAutocomplete-popper {
  font-family: "BrandonTextWeb-Regular" !important;
}

body {
  color: #232323;
  overflow-x: hidden;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.zindex-1 {
  z-index: 1;
}

.zindex-11 {
  z-index: 11;
}

.f-11 {
  font-size: 11px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-18 {
  font-size: 18px;
}

.pink-txt {
  color: #cb007b !important;
}
.bg-light-pink {
  background-color: #fabed1 !important;
}
.line-height-1_75 {
  line-height: 1.75;
}

.bg-pink {
  background: #cb007b;
}

.h-80 {
  height: 80px;
}

.brandon-regular {
  font-family: "BrandonTextWeb-Regular" !important;
}

.brandon-Medium {
  font-family: "Brandon Text Medium" !important;
}

.brandon-Bold {
  font-family: "Brandon Text" !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.text-dark {
  color: #232323 !important;
}

.txt-lightgray {
  color: #919191;
}

.txt-darkgreen {
  color: #2e482b;
}

.list-style-none {
  list-style: none;
}

hr {
  border-color: #d9d9d9 !important;
}

.light-white-hr {
  border-color: #d9d9d957 !important;
}

.text-decoration-none {
  text-decoration: none;
}

.vertical-align-top {
  vertical-align: top;
}
.error_boundary_img {
  height: calc(100vh - 200px);
}

.gray_shadow {
  filter: grayscale(100%);
}
.border-radius-20 {
  border-radius: 20px;
}
.my_custom_css {
  display: none !important;
}
.only_one_line_text {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  // line-height: 24px;
  //white-space: normal;

  display: inline-block;
  width: 100%;
  padding-bottom: 10px;
  white-space: normal;
  -webkit-appearance: none !important;
  word-break: break-all !important;
}
.only_two_line_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  // line-height: 24px;
  white-space: normal;
}
// Pink CheckBox Css start
.pink-checkbox {
  .custom-check-box {
    width: 20px;
    height: 20px;
    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: #cb007b;
          background-color: #ffffff;
        }
        &::after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.898' height='7.014' viewBox='0 0 9.898 7.014'%3E%3Cpath id='Path_411' data-name='Path 411' d='M-8.169-3.752l5.362-5.362.826.826L-8.169-2.1l-3.71-3.71.826-.826Z' transform='translate(11.879 9.114)' fill='%23cb007b'/%3E%3C/svg%3E%0A") !important;
        }
      }
      ~ .gray-control-label {
        &::before {
          border-color: #919191;
        }
      }
    }
    &:focus {
      ~ .custom-control-label {
        &::before {
          box-shadow: none;
        }
      }
      &:not(:checked) {
        ~ .custom-control-label {
          &::before {
            border: 1px solid #919191;
          }
        }
      }
    }
  }
  .custom-control-label::before,
  .custom-control-label::after {
    border: 1px solid #919191;
    width: 20px;
    height: 20px;
    top: 1px;
  }
  .custom-control-label::after {
    border: none;
  }
  .custom-control-label {
    padding-left: 10px;
    cursor: pointer;
  }
}
// Pink CheckBox Css end
// --- Border-radius CSS Start ---

.border-radius-20 {
  border-radius: 20px !important;
}

// --- Border-radius CSS End ---

// --- Width CSS Start ---

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

// --- Width CSS End ---

// --- Hight CSS Start ---

.h-36 {
  height: 36px;
}

.h-40 {
  height: 40px;
}

// --- Hight CSS End ---

// --- Button CSS Start ---

.theme-pink-btn {
  width: 50px;
  height: 50px;
  border-radius: 25px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #cb007b, #ff75ba);
  border: none !important;
  transition: background 0.5s !important;

  &:hover {
    background: linear-gradient(90deg, #cb007b, #ff75ba);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #d00b812e !important;
  }

  &.w-160 {
    width: 160px;
  }
}

.lightgray-btn {
  border: 1px solid #c1c1c1 !important;
  background: #fff !important;
  color: #c1c1c1 !important;
  border-radius: 20px !important;

  &:hover {
    background: #c1c1c1 !important;
    color: #fff !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #ececec !important;
  }
}

.darkgray-btn {
  border: 1px solid #919191 !important;
  background: #fff !important;
  color: #919191 !important;
  border-radius: 20px !important;

  &:hover {
    background: #919191 !important;
    color: #fff !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #ececec !important;
  }
}

.pinkline-btn {
  border: 1px solid #cb007b !important;
  color: #cb007b !important;
  background: #fff;
  outline: none !important;
  font-size: 14px;
  font-family: "BrandonTextWeb-Regular";

  &:hover {
    background: linear-gradient(2deg, #cb007b, #ff75ba);
    color: #fff !important;
    border: 1px solid #fb6bb5 !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #d00b812e !important;
  }
}

.graytxt-btn {
  color: #919191 !important;
  outline: none;
  border: none;
  font-size: 15px;

  &:hover {
    background: #919191 !important;
    color: #fff !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #ececec !important;
  }
}

// --- Button CSS End ---

// Form Control CSS Start

input:-webkit-autofill {
  -webkit-background-clip: text;
}

.form-control {
  &.input-custom {
    height: calc(1.5em + 0.75rem + 9px);
    border: none;
    border-bottom: 2px solid #919191;
    border-radius: 0px;
    padding-left: 0;
    outline: none !important;
    box-shadow: none !important;
    font-size: 15px;
    position: relative;

    &:focus {
      border-color: #ff75ba;
    }
  }
}

// @media screen and (min-width:992px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width: 980px !important;
//     }
// }
// @media screen and (min-width:1200px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width: 1140px !important;
//     }
// }
// @media screen and (min-width:1200px) and (max-width:2400px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width: 100% !important;
//     }
// }

// @media screen and (min-width:1200px) and (max-width:1400px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         max-width    : 100% !important;
//         padding-left : 5% !important;
//         padding-right: 5% !important;
//     }
// }

// @media screen and (min-width:1401px) and (max-width:1600px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         padding-left : 10% !important;
//         padding-right: 10% !important;
//     }
// }

// @media screen and (min-width:1601px) and (max-width:1800px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         padding-left : 15% !important;
//         padding-right: 15% !important;
//     }
// }

// @media screen and (min-width:1800px) and (max-width:2400px) {
//     .container, .allrsfilter-sticky .allrestaurant-nav {
//         padding-left : 20% !important;
//         padding-right: 20% !important;
//     }
// }
