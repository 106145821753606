.customLoadingComp-container{
    // Loader CSS Start

.loader-mainwrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background: #ffffffbd;
    z-index: 111;
    background: linear-gradient(0deg, #cb007bb0, #ff75baed);

    .loader {
        .loader-logo {
            max-width: 175px;
        }

        .loading {
            .dot {
                position: relative;
                width: 16px;
                height: 16px;
                margin: 0.75em;
                border-radius: 50%;

                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: inherit;
                    border-radius: inherit;
                    animation: wave 2s ease-out infinite;
                }

                &:nth-child(1) {
                    background: #ffffff;

                    &::before {
                        animation-delay: 0.2s;
                    }
                }

                &:nth-child(2) {
                    background: #ffffff;

                    &::before {
                        animation-delay: 0.4s;
                    }
                }

                &:nth-child(3) {
                    background: #ffffff;

                    &::before {
                        animation-delay: 0.6s;
                    }
                }

                &:nth-child(4) {
                    background: #ffffff;

                    &::before {
                        animation-delay: 0.8s;
                    }
                }

                &:nth-child(5) {
                    background: #ffffff;

                    &::before {
                        animation-delay: 1s;
                    }
                }

            }
        }
    }

}

@keyframes wave {

    50%,
    75% {
        transform: scale(2.5);
    }

    80%,
    100% {
        opacity: 0;
    }
}

// Loader CSS Start
}