body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.splash-screen {
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  animation:run 6s infinite;
}

/* .splash-screen .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;
  animation: ease-in-out infinite alternate;
  animation-name: run;
  animation-duration: 1.2s;
} */

.tooltip > div.tooltip-inner {
  background-color: #cb007b !important;
  color: #ffffff !important;
  text-transform: capitalize;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip > div.arrow::before {
  border-bottom-color: #cb007b !important;
  color: #919191 !important;
}
@keyframes run {
  0% {
    background-image   : url('./assets/images/splash_1.png');
    background-size    : cover;
    background-position: center;
    background-repeat  : no-repeat;
    background-position: ;
  }
  50% {
    background-image   : url('./assets/images/splash_2.png');
    background-size    : cover;
    background-position: center;
    background-repeat  : no-repeat;
  }
  100% {
    background-image   : url('./assets/images/splash_3.png');
    background-size    : cover;
    background-position: center;
    background-repeat  : no-repeat;
  }
}